import { ReactElement, useState } from 'react';
import {
    Box,
    Typography
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';

export const CoreLayout = ({ children }: { children: ReactElement }) => {
    const [open, setOpen] = useState(false);

    return (
        <Box sx={{ display: 'flex', height: '100vh', background: '#F1F5F9' }}>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', px: 4, py: 3 }}>
                    <Box className="header-icon">
                        <LogoutIcon />
                    </Box>
                    <Box className="header-icon">
                        <PersonIcon />
                    </Box>
                    <Box sx={{ flex: 1, textAlign: 'right' }}>
                        <Typography variant="h5">داشبورد</Typography>
                    </Box>
                </Box>
                <Box className="header-linear" />
                {children}
            </Box>
            <Box
                sx={{
                    width: open ? 256 : 64,
                    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                    position: 'relative',
                    background: '#fff',
                    pt: 1,
                    transition: 'all 0.3s ease-out'
                }}
            >
                <Box
                    sx={{
                        width: 32,
                        height: 32,
                        background: '#fff',
                        position: 'absolute',
                        top: 68,
                        left: -18,
                        borderRadius: '50%',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0px 4px 10px 0px'
                    }}
                    onClick={() => setOpen(state => !state)}
                >
                    {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </Box>
                <Box sx={{ mb: 8, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={require('../common/logo.png')} width={40} height={40} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        cursor: 'pointer',
                        flex: 1,
                        justifyContent: open ? 'flex-end' : 'center',
                        p: 1,
                        borderRadius: '5px',
                        width: 'fit-content',
                        margin: open ? '0 15px 0 auto' : 'auto'
                    }}
                >
                    {open && <Typography sx={{ mr: 2 }}>داشبورد</Typography>}
                    <DashboardIcon />
                </Box>
            </Box>
        </Box>
    );
}