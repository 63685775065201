import { useState } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Button,
    OutlinedInput,
    Checkbox,
    ListItemText
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"

export const SearchSection = ({ tankersInfo, selectedTanker, setSelectedTanker }: any) => {
    const [value, setValue] = useState(new Date());
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const tankers = tankersInfo.map(({ data: { name } }: { data: { name: string } }) => name)

    return (
        <Box sx={{ p: 3, display: 'flex', direction: 'rtl' }}>
            <FormControl sx={{ ml: 1, width: 180 }} size='small'>
                <InputLabel id="demo-multiple-checkbox-label">تانکر</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedTanker}
                    onChange={({ target: { value } }) => setSelectedTanker(value)}
                    input={<OutlinedInput label="تانکر" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    sx={{ background: '#fff' }}
                >
                    {tankers.map((name: string) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={selectedTanker.includes(name)} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <DatePicker
                calendar={persian}
                locale={persian_fa}
                style={{ marginLeft: 10, height: 34 }}
                placeholder='تاریخ'
                showOtherDays
                value={value}
            />
            <Button
                variant='contained'
                endIcon={<SearchIcon />}
                className="search_btn"
            >
                جستجو
            </Button>
        </Box>
    );
}