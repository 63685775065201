import './App.css';
import './global.scss';
import { CoreLayout } from './layouts/core.layout';
import { HomePage } from './pages/home';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Navigate to="/dashboard" />
          }
        />
        <Route
          path="/login"
        />
        <Route
          path="/dashboard"
          element={
            <CoreLayout>
              <HomePage />
            </CoreLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
