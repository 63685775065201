import { useState, useMemo } from 'react';
import { MapComponent } from '@terrestris/react-geo/dist/Map/MapComponent/MapComponent';
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import OlLayerTile from 'ol/layer/Tile';
import OlSourceOsm from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import { Icon, Style } from 'ol/style';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import Select from 'ol/interaction/Select';
import { SearchSection } from './search-section';
import { Box, Typography } from '@mui/material';
import tankersInfo from './tmp-data.json';

interface IpopupContent {
    type: string;
    capacity: number;
    name: string;
}

export const HomePage = () => {
    const [selectedTanker, setSelectedTanker] = useState<string[]>(['تانکر ۱', 'تانکر ۲', 'تانکر ۳']);
    const layer = new OlLayerTile({
        source: new OlSourceOsm()
    });
    const map = useMemo(() => new OlMap({
        view: new OlView({
            center: fromLonLat([
                54.357047,
                31.894975
            ]),
            zoom: 12
        }),
        layers: [layer]
    }), []);

    const layers = map.getLayers().getArray() || [];
    // Collect layers to be removed
    const layersToRemove = layers.filter((layer) => {
        // Check for the custom property or layer type (e.g., VectorLayer)
        return layer instanceof VectorLayer;
    });
    // Remove the collected layers
    layersToRemove.forEach((layer) => {
        map.removeLayer(layer);
    });

    // Create features for markers
    const features = tankersInfo
        .filter(({ data: { name } }) => selectedTanker.includes(name))
        .map((marker) => {
            const feature = new Feature({
                geometry: new Point(fromLonLat([marker.lon, marker.lat])),
                data: marker.data,
            });

            // Add style with an icon for each marker
            feature.setStyle(
                new Style({
                    image: new Icon({
                        anchor: [0.5, 1],
                        src: require('../../common/noun-tanker.png'),  // Marker icon URL
                    }),
                })
            );
            return feature;
        });

    // Create vector source and vector layer to hold markers
    const vectorSource = new VectorSource({
        features: features,
    });
    const markerLayer = new VectorLayer({
        source: vectorSource,
    });
    map.addLayer(markerLayer);

    const renderWaterLayer = (waterInfo: any[]) => {
        const waterFeatures = waterInfo.map((marker) => {
            const feature = new Feature({
                geometry: new Point(fromLonLat([marker.lon, marker.lat])),
                data: marker.data,
            });

            // Add style with an icon for each marker
            feature.setStyle(
                new Style({
                    image: new Icon({
                        anchor: [0.5, 1],
                        src: require('../../common/water-drop.png'),  // Marker icon URL
                        width: 20,
                        height: 20
                    }),
                })
            );
            return feature;
        });
        const waterSource = new VectorSource({
            features: waterFeatures,
        });
        const waterLayer = new VectorLayer({
            source: waterSource,
        });
        map.addLayer(waterLayer);
    }

    tankersInfo
        .filter(({ data: { name } }) => selectedTanker.includes(name))
        .map(({ waterInfo }) => renderWaterLayer(waterInfo))

    // Handle marker clicks with Select interaction
    const [popupContent, setPopupContent] = useState<IpopupContent | undefined>(undefined);
    const [popupPosition, setPopupPosition] = useState<any>(null);
    const selectInteraction = new Select();
    map.addInteraction(selectInteraction);
    selectInteraction.on('select', (e) => {
        const selectedFeature = e.selected[0];  // Get the first selected feature
        if (selectedFeature) {
            const data = selectedFeature.get('data');  // Get the data from the feature
            const geometry = selectedFeature.getGeometry();
            if (geometry instanceof Point) {
                const coordinates = geometry.getCoordinates();  // Get the feature's coordinates (only if it's a Point)
                const pixelPosition = map.getPixelFromCoordinate(coordinates);
                setPopupContent(data);
                setPopupPosition(pixelPosition);
            }
        } else {
            setPopupContent(undefined);
            setPopupPosition(null);
        }
    });

    const view = map.getView();
    const hidePopupOnMove = () => {
        setPopupContent(undefined); // Clear the popup content
        setPopupPosition(null); // Hide the popup
    };
    view.on('change:center', hidePopupOnMove); // Hide popup on map pan
    view.on('change:resolution', hidePopupOnMove); // 

    return (
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <SearchSection
                tankersInfo={tankersInfo}
                selectedTanker={selectedTanker}
                setSelectedTanker={setSelectedTanker}
            />
            <Box sx={{ position: 'relative', flex: 1 }}>
                <MapComponent
                    map={map}
                    className='h100'
                />
                {popupContent && (
                    <Box
                        sx={{
                            width: 130,
                            height: 50,
                            background: '#fff',
                            position: 'absolute',
                            left: popupPosition[0],
                            top: popupPosition[1],
                            transform: 'translate(-50%, -110%)',
                            borderRadius: 5,
                            border: '1px solid',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            direction: 'rtl'
                        }}
                    >
                        {popupContent.type === "water" ?
                            <Typography
                                fontSize={12}
                                fontWeight="bold"
                                textAlign="center"
                            >
                                حجم آب باقیمانده:
                                {popupContent.capacity} لیتر
                                <br />
                                حجم آب مصرف شده: ۵۰ لیتر
                            </Typography>
                            :
                            popupContent.type === "tanker" && (
                                <Typography
                                    fontSize={12}
                                    fontWeight="bold"
                                    textAlign="center"
                                >
                                    {popupContent.name}
                                    <br />
                                    حجم آب باقیمانده: ۱۰۰ لیتر
                                </Typography>
                            )
                        }
                    </Box>
                )}
            </Box>
        </Box>
    );
}